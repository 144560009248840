import React from "react";
import { graphql } from "gatsby";
// import { ProfileSection } from "../components/intro/profile";
import { ArticleNavSection } from "../components/article_nav";
import Layout from "../components/layout";
import SeoHelmet from "../components/seo";

const Home = ({ data, pageContext }) => {
  const articlesInfoProps = data.allMdx;
  const { skip } = pageContext;

  const pathname = pageContext.skip > 0 ? `/page/${skip / 10 + 1}/` : "/";

  return (
    <>
      <SeoHelmet pathname={pathname} />
      <Layout addDefaultMainAd={true}>
        {/* <ProfileSection /> */}
        <ArticleNavSection articlesInfoProps={articlesInfoProps} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($skip: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date, frontmatter___weight ], order: [DESC, DESC] }
      limit: 10
      skip: $skip
    ) {
      nodes {
        frontmatter {
          title
          description
          date(formatString: "MMMM D, YYYY")
          category
          tags
          hero_image {
            childImageSharp {
              gatsbyImageData(width: 900, aspectRatio: 2)
            }
          }
          hero_image_alt
        }
        id
        slug
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        totalCount
      }
    }
  }
`;

export default Home;
